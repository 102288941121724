import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import styles from './ACTprogram.module.scss';
import HeroImage from '../components/hero-image/hero-image';
import ActIntroSection from '../components/landing-page/ACT-intro-section';
import ActBenefitsSection from '../components/landing-page/ACT-benefits-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getMentalHealthImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath =
    getMentalHealthImageEdge('mental_health_hero').node.childImageSharp.fluid
      .src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>The ACT Program - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="The Ingenovis Health ACT program is a unique proprietary program that provides Trustaff travelers with the tools and resources to flourish, grow, and advance their careers.
          "
        />
        <meta property="og:title" content="Traveler perks &amp; discounts" />
        <meta
          property="og:description"
          content="The Ingenovis Health ACT program is a unique proprietary program that provides Trustaff travelers with the tools and resources to flourish, grow, and advance their careers.
          "
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta property="og:url" content="https://www.trustaff.com/ACTprogram" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getMentalHealthImageEdge('mental_health_hero').node.childImageSharp
              .fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <div className={`section ${styles.ACT}`}></div>
                <p className={`content`}>
                  The Ingenovis Health ACT program is a unique initiative
                  available to Trustaff's travelers that provides tools and
                  resources to flourish, grow, and advance your career.
                </p>
                <p className={`content`}>
                  Based on real feedback from frontline travelers like you, the
                  creation of the ACT program was inspired by our dedication and
                  commitment to going above and beyond to serve the needs and
                  well-being of the talented people who call Trustaff home.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <ActIntroSection
            fluidTop={
              getMentalHealthImageEdge('act-icons-sq').node.childImageSharp
                .fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <ActBenefitsSection
            fluidTop={
              getMentalHealthImageEdge('ACT_1').node.childImageSharp.fluid
            }
            fluidBottom={
              getMentalHealthImageEdge('ACT_2').node.childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^mental-health/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
