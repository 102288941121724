import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './ACT-benefits-section.module.scss';

export default ({ fluidTop, fluidBottom }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className={`title is-3 has-text-info`}>
            Resources available to you through the Ingenovis Health ACT program
          </h3>
          <div className="content">
            <p className={`content`}>
              In addition to our 24/7 support and{' '}
              <Link to="/perks-discounts/">perks and discounts</Link>, all
              Trustaff travelers are eligible for additional resources through
              the ACT program. These tools are focused around your personal
              wellness and the advancement of your professional career.
              Resources specific to Trustaff team members include:
            </p>
            <p>
              <li>
                Extensive{' '}
                <Link to="/trustaff-traveler/mental-health/">
                  resources for mental health and wellness
                </Link>
              </li>
              <li>
                Professional{' '}
                <Link to="/travel-nursing/license-reimbursement/">
                  licensure reimbursement
                </Link>
              </li>
              <li>Clinical advocacy and coaching</li>
              <li>
                Veteran and first responder support through the{' '}
                <a href="https://bouldercrest.org/warriorpathh/">
                  Boulder Crest Foundation
                </a>
              </li>
              <li>
                <a href="https://images.nurse.fastaff.com/Web/USNursing/%7B2f401084-ed79-4296-ae06-bf2cb8fae64d%7D_Quit_For_Life_FI_Member_Program_Brochure_Optum_73220_(4).pdf">
                  Quit Tobacco for Good
                </a>{' '}
                program
              </li>
              <li>
                <a href="https://images.nurse.fastaff.com/Web/USNursing/%7B296330ac-51a0-4838-820d-6ae3e71322e5%7D_PDF-UA-SimplyEngaged-Member-LrgGrp-Flier.pdf">
                  $200 credit for health and wellness activities
                </a>
              </li>
              <li>
                <a href="https://images.nurse.fastaff.com/Web/USNursing/%7Be4bc8726-6e7f-4b15-866a-7e31075128ea%7D_PDF-UA-Rally-Journey-Flier.pdf">
                  Rally for Health wellness
                </a>{' '}
                program
              </li>
              <li>
                Discounted{' '}
                <Link to="/perks-discounts/">membership to Planet Fitness</Link>{' '}
                locations nationwide
              </li>
              <li>
                Discounted{' '}
                <Link to="/perks-discounts/">subscription to Hello Fresh</Link>{' '}
                meal services
              </li>
            </p>
            <p className={`title is-5`}>
              We value your unique experience and perspective on your
              assignment, your career, and your life.
            </p>
            <p className={`content`}>
              If you have a recommendation for the ACT program, how we can
              better serve you, or additional resources for healthcare
              professionals, we would love to hear your feedback.
            </p>
            <a href="/contact-us/" className="button is-primary">
              Contact us
            </a>
          </div>
        </div>
        <div className={`column is-5 is-offset-1`}>
          <Img
            fluid={fluidTop}
            className={styles.aboutImageWrapper}
            alt="ACT Program Benefits"
          />
          <Img
            fluid={fluidBottom}
            className={`is-hidden-mobile ${styles.aboutImageWrapper} ${styles.img2}`}
            alt="ACT Program Benefits"
          />
        </div>
      </div>
    </div>
  );
};
