import React from 'react';
import Img from 'gatsby-image';
import styles from './ACT-intro-section.module.scss';

export default ({ fluidTop }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h1 className={`title is-3 has-text-info`}>
            What is the Ingenovis Health ACT Program?
          </h1>
          <div className="content">
            <p className={`content`}>
              The ACT program (Advocacy, Career, Tools) was created as part of{' '}
              <a href="https://www.ingenovishealth.com">Ingenovis Health's</a>{' '}
              clinician-first movement. Our goal is to offer more to our
              healthcare professionals beyond the typical offerings of the past,
              focused on personal wellness, service and support, training and
              development, achievement and recognition, and forward-thinking
              industry inspiration.
            </p>
            <p>
              We are committed to bringing awareness to the challenges facing
              healthcare providers, amplifying the voices of the professional
              community and sharing knowledge and resources to ensure our
              clinical talent thrives.
            </p>
            <a
              href="https://www.ingenovishealth.com/ACTprogram"
              className="button is-primary"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className={`column is-5 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={fluidTop}
            className={styles.aboutImageWrapper}
            alt="ACT Program Icons"
          />
        </div>
      </div>
    </div>
  );
};
